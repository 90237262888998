// @flow
import React from 'react';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  GlossaryLink,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
  },
};

class Blog_2019_06_15_Rocky_Mouth extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CI" title="Rocky Mouth" />
        <Image
          image={data._2}
          caption="Yes I am in there somewhere. Photo Credit Vickie A."
        />
        <p>
          There are so many cool things about{' '}
          <Link to="http://ropewiki.com/Rocky_Mouth_Canyon">Rocky Mouth</Link>{' '}
          in high flow that make a perfect place to learn about waterfall
          rappelling. It has a very short <GlossaryLink>approach</GlossaryLink>,
          you pass the bottom of the{' '}
          <GlossaryLink>technical section</GlossaryLink> on the way up so you
          can see every obstacle before having to commit, you can pretty much
          see all <GlossaryLink>anchors</GlossaryLink> from the approach, and
          you can bail out any time. With these things in mind, I present the
        </p>
        <h2>
          10 things I learned in my first ridiculously hard waterfall rappel
        </h2>
        <ol>
          <h3>
            <li>Your brain turns off</li>
          </h3>
          <p>
            Rocky Mouth is such a simple canyon but under these conditions
            having a plan of attack for that waterfall{' '}
            <em>before you are there</em> is crucial. When it is time to{' '}
            <GlossaryLink id="rigging">rig</GlossaryLink> and{' '}
            <GlossaryLink>rappel</GlossaryLink>, you brain will be concentrating
            on other things and won't be able to focus. At least that's how it
            was for me. I think I need training.
          </p>
          <h3>
            <li>Water spraying you in all directions sucks</li>
          </h3>
          <p>
            About 10 feet from the top of the rappel all the water flow
            converges. I figured that would be the most difficult part of the
            rappel because water would be coming from all directions and you
            would basically be blind. It was true that I was basically blind at
            that part of the rappel. However, lots of cold water spraying you
            from all directions is much more difficult than just not being able
            to see.
          </p>
          <p>
            It's like jumping into a pool of water - that initial shock of
            weightlessness combined with your entire body telling you that you
            are now covered in freezing cold water. Except that it's not just a
            quick shock and you swim to the surface. It's a perpetual sensation.
            Despite this it still wasn't the most difficult section of the
            rappel.
          </p>
          <h3>
            <li>All that water on concentrated on your head is worse</li>
          </h3>
          <p>
            After getting down through the poltergeist of water at the top of
            the rappel, the water converges into one concentrated flow. This was
            the most difficult part of the rappel by far.
          </p>
          <p>
            Imagine everything I described above with water splashing in all
            directions and not being able to see or feel. Now stick a 20 lb
            dumbbell on your head. Also make it so if you don't look down you
            can't breathe. And if you <em>do</em> look down it's going to try
            and force your head off to the side. Maybe strain your neck, maybe
            flip you over. Who knows. I think I need training.
          </p>
          <p>
            It is possible to bypass much of this by stepping to the right and
            awkwardly descending. However, it's slippery with all the water and
            moss, and it's really trying to pull you back into the worst part of
            it. And there are no circumstances where you can descend the
            waterfall without having the full force of water right on your head.
          </p>
          <Image
            image={data._1}
            caption="Trying to avoid the most difficult part of the flow. Photo Credit Vickie A."
          />
          <h3>
            <li>Rain jackets can only do so much</li>
          </h3>
          <p>
            I didn't bring a <GlossaryLink>wetsuit</GlossaryLink> on this trip.
            In hindsight I'm glad I didn't. I did bring a rain jacket and
            figured it would help with the worst of the water. That became a
            problem, though, because I held the{' '}
            <GlossaryLink>rope</GlossaryLink> with my{' '}
            <GlossaryLink>guide hand</GlossaryLink> above the{' '}
            <GlossaryLink>rappel device</GlossaryLink>. Tons of water came in at
            that opening where my hand was. I would recommend either wrapping
            your wrist with tape so water can't get through or just know that
            you will still get wet and be okay with that.
          </p>
          <h3>
            <li>It feels much colder than still water</li>
          </h3>
          <p>
            Since the water is flowing it sucks away body heat much faster than
            a stagnant pool of water. It wasn't that big of a deal for me since
            the water section was so short. However, my hands were completely
            numb and I remember thinking they didn't ever get that cold in{' '}
            <Link to="http://ropewiki.com/Heaps_Canyon">Heaps</Link>.
          </p>
          <h3>
            <li>Nothing is truly waterproof</li>
          </h3>
          <p>
            Since there is so much flow in that waterfall most devices that
            claim to be waterproof or water-resistant likely have not been
            tested in this type of scenario. I am specifically thinking of
            high-end phones that are now waterproof up to like 1 meter. Those
            types of devices will get destroyed. How about a GoPro in its
            waterproof casing? Maybe, maybe not. I put mine away and I'd
            recommend you do the same.
          </p>
          <h3>
            <li>Remove everything from your helmet</li>
          </h3>
          <p>
            I had a headlamp on my <GlossaryLink>helmet</GlossaryLink> when I
            started that rappel. When I finished the headlamp was dangling by
            one of the little hooks that pinch it on. I read from a previous
            trip report that something similar happened to someone's GoPro that
            was attached to their helmet. If you have something on your helmet
            it can easily be forced off so just remove it to be safe.
          </p>
          <h3>
            <li>Things in your backpack lid should be sturdy</li>
          </h3>
          <p>
            One person in our group had sunglasses in the lid of their{' '}
            <GlossaryLink>backpack</GlossaryLink>. The force of the falls broke
            them. I'd go ahead and just suggest that things will get destroyed
            much more than usual on so if it can break, leave it in the car.
          </p>
          <Image
            image={data._3}
            caption="Sunglasses that didn't make it. Photo Credit Vickie A."
          />
          <h3>
            <li>Rope gets tangled much easier</li>
          </h3>
          <p>
            This seems pretty obvious in hindsight. With all that flow you won't
            be able to see where the rappel line was deployed or even if it's
            tangled 10 feet down. The first person down noticed it had caught on
            something and was able to untangle it quickly and without problems.
            But still she wasn't able to see it was caught until she was at that
            point. Next time it might be a good idea feed the rope down. I don't
            know. There is probably a better way to do this. I think I need
            training.
          </p>
          <h3>
            <li>Mind that friction</li>
          </h3>
          <p>
            I am using a <GlossaryLink>SQWUREL</GlossaryLink> as my rappel
            device this year. I have heard complaints that under some situations
            the rope can pop off the horn so canyoneers unexpectedly lose that
            friction while on rappel. That had never happened to me before and I
            never really gave it any thought until this rappel. It popped off
            and I didn't realize why I was going down so fast until I was at the
            bottom and saw what had happened.
          </p>
        </ol>
        <h3>What I didn't learn - know what to do with that knife</h3>
        <p>
          Having a knife that is quickly accessible is essential when
          canyoneering. I guess. I've seen a couple of posts from guides who
          canyoneer in predominantly{' '}
          <GlossaryLink id="c-water-rating">class C</GlossaryLink> areas who
          make this claim.{' '}
          <Link to="http://www.onropecanyoneering.com/canyoneering-gear/access-point">
            On Rope Canyoneering
          </Link>{' '}
          even sells a version their <GlossaryLink>chest pouch</GlossaryLink>{' '}
          with a knife holder on the front of it. I had mine accessible but
          didn't need to use it at all and still don't understand its importance
          or what types of situations would call for its immediate
          accessibility. I think I need training.
        </p>
        <h2>TL;DR</h2>
        <p>I think I need training.</p>
        <AllImagesLink id="2019-06-15-rocky-mouth">
          View all images and several video clips
        </AllImagesLink>
      </Layout>
    );
  }
}

export default withFrontMatter('2019-06-15-rocky-mouth')(
  Blog_2019_06_15_Rocky_Mouth
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2019-06-15-rocky-mouth/62618194_416205122558352_4015760023366926336_n.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2019-06-15-rocky-mouth/64760220_2284280948320583_5757294383435087872_n.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2019-06-15-rocky-mouth/64326670_412600429333557_8448037329330765824_n.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
